import React from 'react';
import {Helmet} from 'react-helmet';
import {getOutlet} from 'reconnect.js';
import Config from '../../../data.json';

export function renderHtmlHelmet(props) {
  const {location} = props;

  if (location.pathname.indexOf('admin') !== -1) {
    return (
      <Helmet>
        <title>{Config.site_name}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.min.js"
          integrity="sha512-IM60GPudO4jk+ZQm3UlJgKHhXQi5pNDM6mP+pLKL968YgkHMc7He3aGJOVHEZ9rJ4vAaEtJ8W6SKa7Qq4inzBA=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <script src="https://cdn.jsdelivr.net/jsbarcode/3.3.20/JsBarcode.all.min.js" />
        <script src="https://cdn.jsdelivr.net/npm/davidshimjs-qrcodejs@0.0.2/qrcode.min.js" />
        <script src="https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js" />
        <link href="/prism/prism.css" rel="stylesheet" />
        <script src="/prism/prism.js" />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{Config.site_name}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <meta name="description" content="肉桂捲專門店 | 訂購 | 菜單 | 地址" />
      <meta name="author" content="RevtelTech 忻旅科技股份有限公司" />

      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${Config.gaId}`}
      />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&family=Noto+Serif+TC:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}

export function renderProfileCustomSection(sectionId, activePath, extraData) {
  // let {action, order_id, order_number} = extraData;
  if (sectionId === 'A') {
    return (
      <div
        style={{
          maxWidth: 'var(--contentMaxWidth)',
          width: '100%',
          margin: '20px auto 0px auto',
          paddingLeft: 20,
          paddingRight: 20,
        }}>
        <div
          style={{
            backgroundColor: '#f0f0f0',
            padding: 'var(--basePadding)',
            borderRadius: 10,
            fontSize: '0.9rem',
            fontWeight: '500',
            textAlign: 'center',
          }}>
          2023-04-24 前的訂單，請
          <a
            target="_blank"
            href="https://legancy.dotdothotel.com/profile/orders"
            rel="noreferrer"
            style={{marginRight: 10, marginLeft: 10}}>
            點此
          </a>
          查閱。
        </div>
      </div>
    );
  }
  return null;
}