import {css} from 'styled-components';

export default css`
  /* #rev-GlobalSpinner {
  } */

  #rev-ProductListPage .rev-FilterMenuL2,
  #rev-ProductDetailPage .rev-FilterMenuL2 {
    position: sticky;
    top: 72px;
    margin: 0px 10px;

    & > .header {
      padding: 23px 0px;
      font-size: 1.25rem;

      & > .label {
        display: none;
      }

      &::before {
        content: '產品列表';
        flex: 1;
        font-size: 1.3rem;
      }
    }

    & > .L1 {
      background-color: transparent;
      border-radius: 0px;
      padding: 0px;
      margin-bottom: 0px;
      box-shadow: none;

      & > .header {
        padding: 10px 0;
        border-top: 1px solid #d0d0d0;
        font-size: 1rem;
        color: #000000;
      }

      & > .L1-items {
        border-top: 1px solid #d0d0d0;
        padding-left: 15px;
        overflow: hidden;

        & > .L2 {
          padding: 5px 10px;
        }

        & > .L2 > .header {
          font-size: 0.95rem;
          color: #6f6f6f;
        }

        & > .L2.selected > .header {
          font-size: 0.95rem;
          color: var(--primaryColor);
        }
      }

      &:last-child {
        border-bottom: 1px solid #d0d0d0;
      }
    }
  }

  /*  __CODEGEN_START__ */ 

  #rev-SiteNavBar {
    border-bottom: 1px solid var(--primaryColor);

    & > .maxwidth-container {
      max-width: calc(100vw - 80px);
    }

    & > .maxwidth-container > .nav-items {
      justify-content: flex-end;
    }

    & > .maxwidth-container > .logo-box > a > img {
      @media screen and (max-width: 450px) {
        height: 25px !important;
      }

      @media screen and (max-width: 320px) {
        height: 20px !important;
      }
    }
  }

  #rev-SiteFooter {
    & > .content > .inner > .site-logo {
      width: 190px;
      height: auto;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    & > .content > .inner > .inner-right > .lang-selector {
      display: none;
    }
  }

  #rev-AboutPage {
    background-color: #ffffff;
  }

  #rev-ProductListPage {
    & > .content > .rev-FilterMenuTop {
      padding: 35px 20px 50px 0px;
      background-color: #ffffff;
      z-index: 99;
    }

    & > .content > .rev-FilterMenuTop > .header {
      display: none;
    }

    & > .content > .rev-FilterMenuTop > .items > * {
      margin: 0px;
      border-radius: 0px;
      max-width: 180px;
      width: 100%;
    }

    & > .content > .rev-FilterMenuTop > .items > *:nth-child(odd) {
      border: 1px solid var(--secondColor);
    }

    & > .content > .rev-FilterMenuTop > .items > *:nth-child(even) {
      border: 1px solid var(--secondColor);
      border-right: none;
      border-left: none;
    }

    & > .content > .rev-FilterMenuTop > .items > *:last-child {
      border-right: 1px solid var(--secondColor);
    }

    & > .content > .rev-FilterMenuTop > .items > *.selected {
      background-color: var(--secondColor);
    }

    & > .content > .rev-FilterMenuTop > .items > * > .header {
      font-size: 0.85rem;
      color: var(--secondColor);
    }

    & > .content > .rev-FilterMenuTop > .items > *.selected > .header {
      color: #ffffff;
    }

    @media screen and (max-width: 580px) {
      & > .content > .rev-FilterMenuTop > .items {
        flex-direction: column;
      }

      & > .content > .rev-FilterMenuTop > .items > *:nth-child(even) {
        border: 1px solid var(--secondColor);
        border-top: none;
        border-bottom: none;
      }
    }
  }

  #rev-CheckoutInfoPage, #rev-CheckoutReviewPage {
    & > .container > .main > div > form > div:nth-child(4) {
      display: none;
    }
  }

  #rev-OrderPage {
    & .invoice-panel {
      display: none;
    }
  }

@import
url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&family=Noto+Serif+TC:wght@400;500;700&display=swap');


body {
  font-family: 'Noto Serif TC','Noto Sans TC', serif,  sans-serif !important;
}
/* __CODEGEN_END__  */
`;
